import React from 'react'
import Layout from '../layout'

const NotFoundPage = () => (
  <Layout>
    <div className="container max-w-5xl mx-auto px-8 lg:px-0">
          <div className="tag-container mb-12">
            <h1>NOT FOUND</h1>
            <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </div>
    </div>
  </Layout>
)

export default NotFoundPage
